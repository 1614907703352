import React, { useEffect, useRef, useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import { auth } from '../../../firebase/firebase';
import { useActions } from '../../../hooks/useActions';

const Layout = () => {
  const { setUser, setIsAuth, logout } = useActions('auth');
  const navbarRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser({ name: authUser.displayName, email: authUser.email });
        setIsAuth(true);
      } else {
        logout();
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);


  const handleNavbarLoad = () => {
    if (navbarRef.current) {
      setHeaderHeight(navbarRef.current.offsetHeight);
    }
  };

  return (
    <>
      <Navbar ref={navbarRef} onLoad={handleNavbarLoad} />

      <main style={{ paddingTop: headerHeight }}>
        <Outlet />
      </main>

      <Footer />
    </>
  );
};

export default Layout;
