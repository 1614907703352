import React from 'react';
import { useSelector } from 'react-redux';
import TextComponent from './TextComponent';
import BlackBtn from './BlackBtn';

const GetStarted = () => {
  const { getStarted } = useSelector(state => state.sections.sections);

  return (
    <section className='w-full h-[50vh] flex items-center justify-center font-inter border-dotted-section'>
      <div className='w-[90%] xl:w-[68%] sm:h-[90%] p-4 rounded-[20px] sm:rounded-[40px] flex flex-col items-center text-center justify-center bg-gradient-yellow font-inter text-white' >

        <TextComponent
          Tag={"h1"}
          className='text-[13px] sm:text-[16px] lg:text-[20px] xl:text-[38px] font-[700] bg-transparent text-center'
          title_key={getStarted?.title_key}
          fieldName='title'
          value={getStarted?.title}
        />

        <TextComponent
          Tag={"p"}
          className='text-[9px] md:text-[12px] xl:text-[18px] font-[400] w-[100%] sm:w-[90%] xl:w-[63%] mt-3 mb-5 sm:mb-10 bg-transparent text-center'
          title_key={getStarted?.title_key}
          fieldName='desc'
          value={getStarted?.desc}
        />

        <BlackBtn title='CONTACT US' className='sm:text-[14px] px-10 py-3 sm:w-[236px] sm:font-[600] sm:py-[20px] sm:px-[40.05px]' to='/contact-us'/>
      </div>
    </section>
  )
}

export default GetStarted;
