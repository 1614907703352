import React from 'react'
import FeatureCard from '../../../Components/FeatureCard'
import TextComponent from '../../../Components/TextComponent';
import {useSelector} from 'react-redux'

const Advantages = () => {
  const {advantages, features} = useSelector(state => state.sections.sections)

  const leftColumn = features?.features?.slice(0, Math.round(features?.features?.length / 2));
  const rightColumn = features?.features.filter(item => !leftColumn.includes(item))

  return (
    <section className='w-full min-h-[100vh] flex items-center justify-center border-dotted-section'>
      <div className='w-full lg:w-[90%] h-[100%] flex flex-col items-center justify-center'>
        <div className='w-full p-6 sm:p-2 flex flex-col items-center justify-center text-center'>

          <TextComponent 
            Tag={"h1"} 
            className='text-[30px] xl:text-[50px] font-[700]'
            title_key={advantages?.title_key}
            fieldName='title'
            value={advantages?.title}
          />

          <TextComponent 
            Tag={"p"} 
            className='text-[10px] lg:text-[19px] font-[550] w-[95%] sm:w-[60%] lg:w-[34%] text-left leading-[30px] mt-2'
            title_key={advantages?.title_key}
            fieldName='desc'
            value={advantages?.desc}
          />

              
          <TextComponent 
            Tag={"span"} 
            className='text-[14px] md:text-[18px] xl:text-[20px] font-[650] text-[#2e3397] sm:py-3 md:py-5 text-center'
            title_key={advantages?.title_key}
            fieldName='subtitle'
            value={advantages?.subtitle}
          />

        </div>

        <div className='w-full flex flex-col md:flex-row items-center justify-center xl:space-x-3'>
          <div className='w-full md:w-[45%] lg:w-[35%] flex flex-col items-center justify-center'>
            {leftColumn?.map((feature) => (
              <FeatureCard key={feature.index} {...feature} index={feature.index}/>
            ))}
          </div>

          <div className='w-full md:w-[45%] lg:w-[35%] flex flex-col items-center justify-center'>
            {rightColumn?.map((feature) => (
              <>
                <FeatureCard key={feature.index} {...feature} index={feature.index}/>

                {feature.index === 3 && (
                  <div className='w-[90%] lg:w-[80%] xl:w-[100%] p-4 md:p-0 flex items-center justify-center mx-2 my-2 xl:m-2'>
                    <img className='w-[89.4%] mx-2' src="/Colored-Arrows.jpg" alt="Arrows" />
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Advantages;
