import { firestore } from "../firebase/firebase"

export const loadSections = async () => {
  try {
    const snapshotsSections = await firestore.collection('sections').get()
  
    let sectionsDataArray = {}
  
    snapshotsSections.forEach(doc => {
      const secitonData = doc.data()
      sectionsDataArray = {...sectionsDataArray, [secitonData.title_key]: secitonData}
    })  
    
    console.log(sectionsDataArray)
    return sectionsDataArray

  } catch (e) {
    console.error(e)
    return {}
  }
}