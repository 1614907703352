import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BlackBtn from '../../Components/BlackBtn';
import NavbarLink from '../../Components/NavbarLink';
import {useLoaderData} from 'react-router-dom'
import { useActions } from '../../../hooks/useActions';
import {useSelector} from 'react-redux'
import { updateSectionsDataFirestore } from '../../../firebase/utils';
import Icons from '../../Components/Icons';
import ModalConfirmSaveSections from '../../Modals/ModalConfirmSaveSections';
import { auth } from '../../../firebase/firebase';
import { logout } from '../../../store/actions/authActions';
import { setupDatabaseData } from '../../../firebase/setupDatabaseData';

const Navbar =  React.forwardRef(({ onLoad }, ref) => {
  const loadedSections = useLoaderData()
  
  const {setSections, setIsChangeMode} = useActions('sections')
  const {isChangeMode, sections} = useSelector(state => state.sections)
  const {isAuth} = useSelector(state => state.auth)

  const [isModalConfirm, setIsModalConfirm] = useState(false)

  useEffect(() => {setSections(loadedSections)}, [loadedSections])

  const logoutUser = async () => {
    try {
      await auth.signOut();
      logout()
    } catch (error) {
      console.error('Error occurred during logout:', error);
      alert(error)
    }
  }

  const saveSections = async () => {
    await updateSectionsDataFirestore(sections)
    setIsChangeMode(false)
    setIsModalConfirm(false)
  }
  
  const links = [
    {name: 'HOME', path: '/home'},
    {name: 'ABOUT US', path: '/about-us'},
    {name: 'SERVICES', path: '/our-services'},
  ]

  return (
    <>
      <ModalConfirmSaveSections 
        isActive={isModalConfirm} 
        setIsActive={setIsModalConfirm} 
        acceptCallback={saveSections} 
        rejectCallback={() => setIsModalConfirm(false)}
      />
    
      <header ref={ref} onLoad={onLoad} className='w-full sm:h-[85px] p-4 lg:p-12 fixed top-0 flex items-center justify-center lg:justify-center bg-white border-[#FFFFFF] navbar-shadow z-[9999]'>
        <div className='w-full lg:w-[80%] flex items-center justify-center sm:justify-between h-full'>
          <div className='flex items-center justify-between font-inter h-full'>
            <Link to='/' className='w-[100px] lg:w-[30%] h-full sm:flex items-center justify-end hidden'> 
              <img className='hidden lg:block' src="/ezdata-logo.jpg" alt="EZDataMigration"/> 
              <img className='block lg:hidden' src="/OnlyLogo.png" alt="EZDM" />
            </Link>

            <div className='flex flex-wrap sm:flex-nowrap items-center justify-center space-x-2.5 sm:space-x-12'>
              {links.map((link, i) => <NavbarLink key={i} {...link}/>)}

              <BlackBtn title={'CONTACT US'} className='lg:ml-[100px] sm:w-[139.67px] sm:py-[15px] sm:px-[30.24px] whitespace-nowrap text-[8px] sm:text-[12px] font-[600]' to='/contact-us'/>
              
              {isAuth && (
                <>
                  <button 
                    onClick={() => setIsChangeMode(!isChangeMode)}
                    title='Enables the mode of changing content'
                  >
                    <Icons icon='pencil' className='w-10 h-10' />
                  </button>
    
                  {isChangeMode && (
                    <div className='flex items-center justify-center space-x-5 p-2 border-2 rounded-lg'>
                      <button className='text-white rounded-lg p-2 bg-[#f34e4e] hover:bg-[#d74545] sm:py-[8px] sm:px-[10px] whitespace-nowrap text-[8px] sm:text-[16px] font-[600]' onClick={() => window.location.reload()}>Cancel</button>  
                      <button className='text-white rounded-lg p-2 bg-[#41b941] hover:bg-[#3ead3e] sm:py-[8px] sm:px-[10px] whitespace-nowrap text-[8px] sm:text-[16px] font-[600]' onClick={() => setIsModalConfirm(true)}>Save</button>
                    </div>
                  )}

                  <button title='Log out' onClick={logoutUser} className='flex items-center justify-between  space-x-2 text-[red] hover:text-[#d86d6d] rounded-lg p-2 sm:py-[8px] sm:px-[10px] whitespace-nowrap text-[8px] sm:text-[16px] font-[600]'>
                    <Icons icon='user' className='w-10 h-10' />
                    <span>Admin</span>
                  </button>

                  {/* <button onClick={() => setupDatabaseData()} className='text-white rounded-lg p-2 bg-[#f34e4e] hover:bg-[#d74545] sm:py-[8px] sm:px-[10px] whitespace-nowrap text-[8px] sm:text-[16px] font-[600]'>
                    Setup database
                  </button> */}
                
                </>
              )}

            </div>
          </div>

        </div>
        
      </header>
    </>
  );
})

export default Navbar;

