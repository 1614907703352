import React from 'react'
import QA from '../../../Components/QA'
import { useSelector } from 'react-redux'
import TextComponent from '../../../Components/TextComponent';

const FAQ = () => {
  const { faqs } = useSelector(state => state.sections.sections);

  return (
    <section className='w-full min-h-[80vh] flex items-center justify-center font-inter my-8 border-dotted-section'>
      <div className='w-[80%] md:w-[65%] flex flex-col items-center justify-center'>

        <div className='flex flex-col items-center justify-center space-y-[3px]'>
          <TextComponent 
            Tag="h2"
            className='text-gradient-yellow font-[500] text-[12px] sm:text-[18px] font-oswald text-center'
            title_key={faqs?.title_key}
            fieldName='mainTitle'
            value={faqs?.mainTitle}
          />
          <TextComponent 
            Tag="h1"
            className='text-[20px] sm:text-[25px] lg:text-[38px] font-[700] text-center'
            title_key={faqs?.title_key}
            fieldName='title'
            value={faqs?.title}
          />
        </div>

        <div className='flex flex-col items-start justify-center w-full space-y-6 sm:space-y-8 my-14'>
          {faqs?.faqs?.map((qa, i) => <QA key={i} i={i} {...qa}/>)}
        </div>

      </div>
    </section>
  )
}

export default FAQ
