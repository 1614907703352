import React from 'react'
import FeatureCard from '../../../Components/FeatureCard'
import { useSelector } from 'react-redux'
import TextComponent from '../../../Components/TextComponent';

const OurFeatures = () => {
  const { features } = useSelector(state => state.sections.sections);

  return (
    <section className='w-full min-h-[110vh] flex items-center justify-center font-inter py-4 my-8'>
      <div className='w-[90%] flex flex-col items-center justify-center'>
        <div className='flex flex-col items-center justify-center'>
          <TextComponent 
            Tag="h2"
            className='text-gradient-yellow font-[500] text-[12px] sm:text-[18px] font-oswald text-center'
            title_key={features?.title_key}
            fieldName='mainTitle'
            value={features?.mainTitle}
          />
          <TextComponent 
            Tag="h1"
            className='font-[700] text-[20px] text-center sm:text-[38px]'
            title_key={features?.title_key}
            fieldName='title'
            value={features?.title}
          />
        </div>

        <div className='w-full flex flex-col xl:flex-row items-center justify-center my-5 sm:my-20 xl:space-x-6'>
          <div className='w-full md:w-[80%] xl:w-[38%] flex flex-col space-y-4'>
            {features?.features?.map((feature, i) => feature.title !== 'Arrows' && <FeatureCard key={i} {...feature} index={i}/>)}
          </div>
          <div className='w-[75%] md:w-[60%] xl:w-[38%] xl:block my-10 lg:my-20 xl:my-0'>
            <img className='w-full' src="/Features.png" alt="Features" />
          </div>
        </div>

      </div>
    </section>
  )
}

export default OurFeatures
