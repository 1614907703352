import React from 'react';
import Icons from '../../Components/Icons';
import OurServices from '../Home/Sections/OurServices';
import { useSelector } from 'react-redux';
import TextComponent from '../../Components/TextComponent';
import { useActions } from '../../../hooks/useActions';

const OurServicesPage = () => {
  const { servicesPage } = useSelector(state => state.sections.sections);
  const {setSectionField} = useActions('sections')

  const updateSupportingDbsList = (index, value) => {
    const updatedSupportingDbsList = servicesPage?.supportingDbsList.map((item, i) => {
      if (i === index) {
        return { ...item, title: value };
      }
      return item;
    });
  
    setSectionField('servicesPage', 'supportingDbsList', updatedSupportingDbsList);
  };

  return (
    <>
      <section className='w-full flex flex-col items-center justify-center border-dotted-section'>
        <div className='w-[95%] xl:w-[74%] h-[80%] flex flex-col sm:flex-row items-center justify-center space-y-10 sm:space-x-10'>
          <div className='flex flex-col items-start justify-end w-[100%] sm:w-[60%] font-inter sm:mr-5 text-left space-y-3'>
            <TextComponent
              Tag={"h1"}
              className='text-[18px] lg:text-[30px] xl:text-[50px] font-[400] font-oswald bg-[#963596] bg-gradient-to-r from-[#963596] to-[#873587] px-2 text-white w-full text-right transition-colors '
              textEditorClassName='border-4 border-dotted'
              title_key={servicesPage?.title_key}
              fieldName='title'
              value={servicesPage?.title}
            />
            <TextComponent
              Tag={"p"}
              className='text-[10px] lg:text-[19px] font-[550] mt-4 text-left px-1 lg:px-3 w-[99%] sm:w-[95%] leading-[20px] md:leading-[35px]'
              title_key={servicesPage?.title_key}
              fieldName='desc'
              value={servicesPage?.desc}
            />
            <TextComponent
              Tag={"p"}
              className='text-[12px] lg:text-[20px] font-[600] text-left w-full tracking-[1px]'
              title_key={servicesPage?.title_key}
              fieldName='databases'
              value={servicesPage?.databases}
            />
          </div>

          <div className='w-[95%] sm:w-[37%] flex flex-col justify-end h-[68%]'>
            <img className='' src="Features.png" alt="Welcome" draggable='false' />
          </div>
        </div>

        <div className='w-[95%] xl:w-[75%] h-[80%] flex flex-col sm:flex-row items-center justify-center space-y-10 sm:space-x-10 my-4'>
          <div className='w-[95%] md:w-[60%] space-y-6'>
            <TextComponent
              Tag={"h2"}
              className='text-[14px] xl:text-[28px] font-[700] text-[#2e3092]'
              title_key={servicesPage?.title_key}
              fieldName='supportingDbsTitle'
              value={servicesPage?.supportingDbsTitle}
            />
            {servicesPage?.supportingDbsList?.map((service, i) => (
              <div key={i} className='flex items-start justify-start space-x-2 sm:space-x-4'>
                <Icons icon='double-arrows-right' className='h-[15px] sm:h-[25px]' />
                <TextComponent
                  Tag={"p"}
                  className='text-[10px] lg:text-[19px] font-[550] whitespace-pre-wrap'
                  title_key={servicesPage?.title_key}
                  fieldName='supportingDbsList'
                  value={service.title}
                  customHandler={(value) => updateSupportingDbsList(i, value)}
                />
              </div>
            ))}
          </div>

          <div className='w-[95%] xl:w-[62%] flex items-center justify-end'>
            <TextComponent
              Tag={"p"}
              className='text-[10px] lg:text-[19px] font-[550] whitespace-pre-wrap w-full xl:w-[90%] my-5 leading-[20px] md:leading-[35px]'
              title_key={servicesPage?.title_key}
              fieldName='desc2'
              value={servicesPage?.desc2}
            />
          </div>
        </div>
      </section>
      
      <OurServices />
    </>
  );
}

export default OurServicesPage;
