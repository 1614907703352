import React, { useState } from 'react';
import Icons from './Icons';
import { useActions } from '../../hooks/useActions';
import { useSelector } from 'react-redux';
import TextComponent from './TextComponent';

const QA = ({ i, question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const {faqs} = useSelector(state => state.sections.sections)

  const {setSectionField} = useActions('sections')

  const updateFAQsList = (index, value, fieldName) => {
    const updatedFAQsList = faqs?.faqs?.map((item, i) => {
      if (i === index) {
        return { ...item, [fieldName]: value };
      }
      return item
    });
  
    setSectionField(faqs?.title_key, 'faqs', updatedFAQsList);
  };


  return (
    <div className={`w-full p-3 sm:p-12 flex items-start justify-start card-shadow rounded-[14px] transition-all duration-500 border-[2px] border-white ${isOpen && 'border-gradient-yellow'}`}>
      <div className='flex flex-col items-start justify-center space-y-4 w-[85%] sm:w-[93%]'>
        {/* <span className='font-[600] text-[14px] sm:text-[16px] md:text-[20px] pt-[3px]'>{question}</span>
        <p className={`font-[400] text-[10px] sm:text-[14px] lg:text-[18px] w-[93%] overflow-hidden transition-all duration-500 ${isOpen ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>{answer}</p> */}
      
        <TextComponent
          Tag={"span"}
          className='font-[600] text-[14px] sm:text-[16px] md:text-[20px] pt-[3px]'
          title_key={faqs?.title_key}
          fieldName='question'
          value={question}
          customHandler={(value) => updateFAQsList(i, value, 'question')}
        />
        <TextComponent
          Tag={"p"}
          className={`font-[400] text-[10px] sm:text-[14px] lg:text-[18px] w-[93%] overflow-hidden transition-all duration-500 ${isOpen ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}
          title_key={faqs?.title_key}
          fieldName='answer'
          value={answer}
          customHandler={(value) => updateFAQsList(i, value, 'answer')}
        />
      </div>

      <button 
        onClick={() => setIsOpen(!isOpen)} 
        className={`
          rounded-full h-[35px] w-[35px] lg:h-[50px] lg:w-[50px] flex items-center justify-center text-[14px] font-[700] transition-all duration-500 
          ${isOpen ? '-rotate-270 bg-gradient-yellow text-white' : '-rotate-90 bg-white elips-shadow'}
        `}
      >
        <Icons icon={'vector'} className={`h-[20px] lg:h-[30px] w-[20px] lg:w-[30px] ${isOpen ? 'fill-white' : 'fill-gradient-yellow'}`}/>
      </button>
    </div>
  );
};

export default QA;
