import React, { useEffect } from 'react';

const Modal = ({ children, isActive }) => {
  useEffect(() => {
    if (isActive) 
      document.body.style.overflow = 'hidden';
    else
      document.body.style.overflow = 'auto';
  }, [isActive]);

  return isActive && (
    <div className="fixed w-full inset-0 flex items-center justify-center bg-[#000000a1] z-[99999] transition-all duration-500">
      {children}
    </div>
  );
};

export default Modal;
