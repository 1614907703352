import React from 'react'
import SocialMediaLink from '../../Components/SocialMediaLink'
import { useSelector } from 'react-redux'
import TextComponent from '../../Components/TextComponent'

const Footer = () => {
  const {footer} = useSelector(state => state.sections.sections)

  const company = [
    {
      title: 'About',
      link: '/about-us'
    },
    {
      title: 'Services',
      link: '/our-services'
    },
    {
      title: 'Features',
      link: '/our-features'
    },
    {
      title: 'FAQs',
      link: '/faqs'
    },
  ]

  const support = [
    {
      title: 'Contact Us',
      link: '/contact-us'
    },
    {
      title: 'Privacy Policy',
      link: '/privacy-policy'
    },
    {
      title: 'Terms & Services',
      link: '/terms-and-services'
    },
  ]

  return (
    <footer className='w-full min-h-[458px] bg-black flex items-center justify-center text-white'>
      <div className='w-full space-y-10 sm:space-y-0 xl:w-[64%] h-[90%] border-b-2 border-white flex flex-wrap sm:flex-nowrap items-start justify-between font-dm-sans py-16 xl:py-16 p-3 xl:p-0'>
        
        <div className='flex flex-col items-start justify-start space-y-5'>
          <div className='w-[100%] sm:w-[350px]'>
            <img className='w-[100%]' src="/footer-logo.png" alt="EZDataMigration" />
          </div>
        </div>

        <div className='flex items-start justify-center flex-col-m space-x-6 lg:space-x-24'>
          <div className='flex flex-col items-start justify-center space-y-2 sm:space-y-4 mb-10'>
            <h3 className='text-[14px] md:text-[20px] font-[700] md:my-3 mx-1 sm:mx-3'>Company</h3>
            {company.map((c, i) => <SocialMediaLink key={i} {...c} isWithAnimation/>)}
          </div>
          <div className='flex flex-col items-start justify-center space-y-2 sm:space-y-4 mb-10'>
            <h3 className='text-[14px] md:text-[20px] font-[700] md:my-3 mx-1 sm:mx-3'>Support</h3>
            {support.map((s, i) => <SocialMediaLink key={i} {...s} isWithAnimation/>)}
          </div>
          <div className='flex flex-col items-start justify-center space-y-2 p-1 mb-10'>
            <h3 className='text-[14px] md:text-[20px] font-[700] md:my-3 break-all'>Contacts us</h3>

            <div className='flex items-center justify-center space-x-2'>
              <img className='' src={`/Social-Media-Icons/Email.png`} alt='Email' />
              <TextComponent
                Tag={"a"}
                className='font-[400] text-[10px] sm:text-[14px] break-all'
                title_key={footer?.title_key}
                fieldName='email'
                value={footer?.email}
                href={"mailto:contact@ezdatamigration.com"}
              />
            </div>

            {/* <div className='flex items-center justify-center space-x-2'>
              <img className='' src={`/Social-Media-Icons/Mark.png`} alt='Mark' />
              <a 
                className='font-[400] text-[10px] sm:text-[14px] whitespace-pre-wrap' 
                target='_blank' href='https://maps.app.goo.gl/9aCm6M6CRFNLFWcC6' rel="noreferrer"
              >
                  {`?????`}
              </a>
            </div> */}

          </div>
        </div>

      </div>
    </footer>
  )
}

export default Footer
