import React from 'react';
import { useSelector } from 'react-redux';
import GetStarted from '../../Components/GetStarted';
import FormContactUs from './FormContactUs';
import TextComponent from '../../Components/TextComponent';

const ContactUs = () => {
  const { contaсtUs } = useSelector(state => state.sections.sections);

  return (
    <>
      <section className='w-full xl:min-h-[50vh] flex items-start justify-center font-inter text-left mb-8 xl:mb-0'>
        <div className='flex flex-col xl:flex-row items-center xl:items-start justify-center w-full xl:w-[65%] h-full xl:space-x-20'>
          <div className='w-[90%] sm:w-[80%] lg:w-[45%] flex flex-col items-start justify-center'>
            <div className='w-full'>
              <img src="/Colored-Arrows.jpg" alt="/Colored-Arrows.jpg" />
            </div>
            <TextComponent 
              Tag="h1"
              className='font-[700] text-[20px] sm:text-[25px] xl:text-[35px]'
              title_key={contaсtUs?.title_key}
              fieldName='title'
              value={contaсtUs?.title}
            />
            <TextComponent 
              Tag="p"
              className='font-[500] text-[14px] lg:text-[20px]  mt-4 mb-6 lg:leading-[25px] text-left'
              title_key={contaсtUs?.title_key}
              fieldName='desc1'
              value={contaсtUs?.desc1}
            />
          </div>
          <div className='w-[90%] sm:w-[80%] lg:w-[52%] xl:w-[40%] flex flex-col items-start justify-center xl:mt-10'>
            <TextComponent 
              Tag="p"
              className='font-[500] text-[14px] lg:text-[20px] mt-4 mb-2 lg:leading-[25px] text-left'
              title_key={contaсtUs?.title_key}
              fieldName='desc2'
              value={contaсtUs?.desc2}
            />
  
            <TextComponent 
              Tag="p"
              className='font-[500] text-[14px] lg:text-[20px] lg:leading-[25px] text-left mt-10 xl:mt-14'
              title_key={contaсtUs?.title_key}
              fieldName='desc3'
              value={contaсtUs?.desc3}
            />
          </div>
        </div>
      </section>
      <FormContactUs />
      <GetStarted />
    </>
  );
}

export default ContactUs;
