import React from 'react'
import {useSelector} from 'react-redux'
import InputContentChange from './InputContentChange'
import { useActions } from '../../hooks/useActions'

const TextComponent = ({Tag, className, textEditorClassName, title_key, fieldName, value, customHandler}, ...props) => {
  const {isChangeMode} = useSelector(state => state.sections)
  const {isAuth} = useSelector(state => state.auth)
  const {setSectionField} = useActions('sections')

  return (
    <>
      {!isChangeMode || !isAuth
        ? <Tag {...props} className={className}>{value}</Tag>

        : <InputContentChange 
            value={value} 
            className={`${className} ${textEditorClassName}`}
            handler={!customHandler ? (value) => setSectionField(title_key, fieldName, value) : (value) => customHandler?.(value)}
          />
      } 
    </>
  )
}

export default TextComponent