import React from 'react'
import TextComponent from './TextComponent'
import { useActions } from '../../hooks/useActions';
import {useSelector} from 'react-redux'

const FeatureCard = ({title, desc, index}) => {
  const {setSectionField} = useActions('sections')
  const {features} = useSelector(state => state.sections.sections)

  const updateFeaturesList = (index, value, fieldName) => {
    const updatedFeatiresList = features?.features?.map((item, i) => {
      if (i === index) {
        return { ...item, [fieldName]: value };
      }
      return item
    });
  
    setSectionField(features?.title_key, 'features', updatedFeatiresList);
  };

  return (
    <div className='w-[90%] lg:w-[80%] xl:w-[100%] p-4 md:p-6 flex items-start justify-start card-shadow mx-2 my-2 xl:m-2'>
      <div className='rounded-full h-[20px] w-[20px] sm:h-[35px] sm:min-w-[35px] px-2 flex items-center justify-center text-[10px] sm:text-[14px] font-[700] mr-2 sm:mr-4 text-white bg-gradient-yellow'>{index}</div>
        <div className='flex flex-col items-start justify-center space-y-4 w-full'>
          <TextComponent
            Tag={"span"}
            className='font-[700] text-[16px] sm:text-[20px]'
            title_key={features?.title_key}
            fieldName='title'
            value={title}
            customHandler={(value) => updateFeaturesList(index, value, 'title')}
          />
          <TextComponent
            Tag={"p"}
            className='font-[400] text-[10px] sm:text-[18px] w-full sm:w-[93%]'
            title_key={features?.title_key}
            fieldName='desc'
            value={desc}
            customHandler={(value) => updateFeaturesList(index, value, 'desc')}
          />
        {/* <span className='font-[700] text-[16px] sm:text-[20px]'>{title}</span>
        <p className='font-[400] text-[10px] sm:text-[18px] w-full sm:w-[93%]'>{desc}</p> */}
      </div>
    </div>
  )
}

export default FeatureCard
