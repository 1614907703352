import './App.css';
import './static/TextStyles.scss'
import './static/Fonts.scss'
import './static/Animations.scss'
import { RouterProvider } from 'react-router-dom';
import router from './components/Routing/Routing';
import { Provider } from 'react-redux'
import { store } from './store/store';


const App = () => {
    return <>
      <Provider store={store}> 
        <RouterProvider router={router}/> 
      </Provider>
    </>
}

export default App;