import React from 'react'
import { Link } from 'react-router-dom'
import Icons from './Icons'
import {useSelector} from 'react-redux'
import { useActions } from '../../hooks/useActions'
import TextComponent from './TextComponent'

const ServiceCard = ({i, icon, title, desc, rmPath}) => {
  const {services} = useSelector(state => state.sections.sections)
  const {setSectionField} = useActions('sections')

  const updateServicesList = (index, value, fieldName) => {
    const updatedServicesList = services?.services?.map((item, i) => {
      if (i === index) {
        return { ...item, [fieldName]: value };
      }
      return item
    });
  
    setSectionField(services?.title_key, 'services', updatedServicesList);
  };


  return (
    <div className='w-[90%] sm:w-[40%] sm:h-[245px] md:w-[40%] md:h-[292px] lg:w-[260px] rounded-[6px] bg-[#FFFFFF] m-2 card-shadow flex flex-col items-center justify-center space-y-4 font-inter p-8 text-center'>
      <Icons icon={icon} className='h-12 sm:h-auto'/> 
        <TextComponent
          Tag={"span"}
          className='font-[700] text-[16px] sm:text-[20px] text-[#000000] breal-all text-center' 
          textEditorClassName='overflow-y-auto'
          title_key={services?.title_key}
          fieldName='title'
          value={title}
          customHandler={(value) => updateServicesList(i, value, 'title')}
        />
        <TextComponent
          Tag={"p"}
          className='font-[400] text-[12px] sm:text-[14px]  text-center'
          textEditorClassName='overflow-y-auto'
          title_key={services?.title_key}
          fieldName='desc'
          value={desc}
          customHandler={(value) => updateServicesList(i, value, 'desc')}
        />
      {/* <span className='font-[700] text-[16px] sm:text-[20px] text-[#000000] breal-all'>{title}</span>
      <p className='font-[400] text-[12px] sm:text-[14px]  break-all'>{desc}</p> */}
      <Link to={`/our-services/${rmPath}`} className='font-[600] text-[11.65px] text-[#000000]'>READ MORE</Link>
    </div>
  )
}

export default ServiceCard
