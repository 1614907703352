import React from 'react'
import Turnstile, { useTurnstile } from "react-turnstile";


const CloudflareTrustlineReChapcha = ({setIsVerifed}) => {
  const turnstile = useTurnstile();

  return (
    <Turnstile
      className='recapcha'
      sitekey="0x4AAAAAAARnopoh6NSYjVv0" 
      onVerify={() => setIsVerifed(true)}
      onError={() => setIsVerifed(false)}
    />
  );
}

export default CloudflareTrustlineReChapcha