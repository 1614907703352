import { createReducer } from "@reduxjs/toolkit"

const initialState = {
  sections: {},
  isChangeMode: false
}

export const sectionReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("SET_SECTIONS", (state, {payload}) => {state.sections = payload})
    .addCase("CHANGE_SECTION", (state, {payload}) => {state.sections[payload.title_key] = payload})
    .addCase("SET_IS_CHANGE_MODE", (state, {payload}) => {state.isChangeMode = payload})
    .addCase("SET_SECTION_FIELD", (state, {payload}) => {state.sections[payload.title_key][payload.fieldName] = payload.value})
});
