import React from 'react'
import { useSelector } from 'react-redux'
import TextComponent from '../../Components/TextComponent';

const OurMission = () => {
  const ourMission = useSelector(state => state.sections.sections.ourMission);

  return (
    <section className='w-full min-h-[70vh] flex items-center justify-center mb-10 sm:py-0 mt-8 border-dotted-section'>
      <div className='w-[90%] xl:w-[70%] h-[80%] flex flex-col sm:flex-row items-center justify-between space-y-10 sm:space-x-10  border-y-[2px] py-3 border-black'>
        <div className='flex flex-col items-center justify-center w-[95%] sm:w-[50%] font-inter sm:mr-5 text-left space-y-10 h-full'>
          <div>
            <TextComponent
              Tag={"h1"}
              className='text-[30px] xl:text-[50px] font-[700]'
              title_key={ourMission?.title_key}
              fieldName='title'
              value={ourMission?.title}
            />
            <TextComponent
              Tag={"p"}
              className='text-[10px] lg:text-[19px] font-[500] my-4'
              title_key={ourMission?.title_key}
              fieldName='desc'
              value={ourMission?.desc}
            />
          </div>
          <div>
            <TextComponent
              Tag={"h2"}
              className='text-[18px] xl:text-[40px] font-[700]'
              title_key={ourMission?.title_key}
              fieldName='title2'
              value={ourMission?.title2}
            />
            <TextComponent
              Tag={"p"}
              className='text-[10px] lg:text-[19px] font-[550]'
              title_key={ourMission?.title_key}
              fieldName='desc2'
              value={ourMission?.desc2}
            />
            <TextComponent
              Tag={"p"}
              className='text-[10px] lg:text-[19px] font-[550] my-8'
              title_key={ourMission?.title_key}
              fieldName='subdesc2'
              value={ourMission?.subdesc2}
            />
          </div>
        </div>
        <div className='w-[80%] sm:w-[40%] flex justify-center h-full items-center'>
          <img className='' src='/Features.png'></img>
        </div>
      </div>
    </section>
  );
}

export default OurMission;
