import { createReducer } from "@reduxjs/toolkit"

const initialState = {
  isAuth: false,
  user: {},
}

export const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("SET_USER", (state, {payload: {email, username}}) => {state.section = {email, username}})
    .addCase("SET_IS_AUTH", (state, {payload}) => {state.isAuth = payload})
    .addCase("LOGOUT", (state) => {state.isAuth = false; state.user = {}})
  })