import React from 'react'
import Paragraph from './Paragraph'
import { useSelector } from 'react-redux'
import { useActions } from '../../../hooks/useActions';
import Icons from '../../Components/Icons';

const TermsAndServices = () => {
  const {termsAndServices} = useSelector(state => state.sections.sections);
  const {isChangeMode} = useSelector(state => state.sections)

  const {setSectionField} = useActions('sections')

  const updateTASParagraphs = (index, value, fieldName) => {
    const updatedTASParagraphs = termsAndServices?.paragraphs?.map(item => {
      if (item.index === index) {
        return { ...item, [fieldName]: value };
      }
      return item
    });
  
    setSectionField(termsAndServices?.title_key, 'paragraphs', updatedTASParagraphs);
  };

  const addNewParagraph = () => {
    setSectionField(termsAndServices?.title_key, 'paragraphs', [...termsAndServices?.paragraphs, {index: termsAndServices?.paragraphs?.length, title: '', desc: ''}])
  }

  const deleteParagraph = (i) => {
    setSectionField(termsAndServices?.title_key, 'paragraphs', termsAndServices?.paragraphs?.filter(paragraph => paragraph.index !== i))
  }

  return (
    <section className='w-full min-h-[100vh] flex flex-col items-center justify-center mb-6 font-inter text-left'>
      <div className='w-[90%] lg:w-[67.5%] flex flex-col items-start justify-end font-inter'>

        <h1 className='text-[25px] sm:text-[45px] font-[700] leading-[60.51px] w-full text-center my-5 sm:my-10'>Terms and Services</h1>

        <div className='flex flex-col items-start justify-start space-y-10 w-full'>
          {termsAndServices?.paragraphs?.map((paragraph, i) => (
            <div key={i} className='w-full flex items-center justify-center'>

            <Paragraph key={paragraph.index} i={paragraph.index} updateHandler={updateTASParagraphs} {...paragraph}/>

            {isChangeMode && (
              <button onClick={() => deleteParagraph(paragraph.index)} className='mt-10 bg-red-500 text-[20px] p-2 m-1 text-white font-[500] rounded-xl hover:bg-red-600'>
                <Icons icon='trash' className='h-8 w-8'/>
              </button>   
            )}

            </div>
          ))}
        </div>

        {isChangeMode && (
          <button onClick={addNewParagraph} className='mt-10 uppercase bg-green-500 p-4 text-[20px] text-white font-[500] rounded-xl hover:bg-green-600'>
            Add new paragraph +
          </button>
        )}


      </div>
    </section>
  )
}

export default TermsAndServices
