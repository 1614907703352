import React, { useState } from 'react'
import CloudflareTrustlineReChapcha from '../ContactUs/CloudflareTrustlineReChapcha'
import { SERVER_URL } from '../../Routing/Routing'
import Cookies from 'js-cookie'
import { auth } from '../../../firebase/firebase'
import {useNavigate} from 'react-router-dom'
import { useActions } from '../../../hooks/useActions'

const LogIn = () => {
  const [form, setForm] = useState({
    'email': '',
    'password': '',
  })

  const [isVerifed, setIsVerifed] = useState(false)

  const navigate = useNavigate()

  const {setUser, setIsAuth} = useActions('auth')

  const submitLogin = async (e) => {
    e.preventDefault();

    if (true) {
      try {
        const userCrendential = await auth.signInWithEmailAndPassword(form.email, form.password)

        const user = userCrendential.user

        await user.getIdToken().then((token) => Cookies.set('idToken', token, { secure: true}))

        setUser({ name: user.displayName, email: user.email })
        setIsAuth(true)
        
        navigate('/home')

      } catch (error) {
        console.error('Error occurred during login:', error);
        alert('Incorrect email or password')
      }
    } else {
      console.error('Verification is not completed');
    }
  };

  


  return (
    <main className='h-screen w-full flex items-center justify-center'>
      <form onSubmit={(e) => submitLogin(e)} className='w-[100%] sm:w-[50%] xl:w-1/4 p-4 sm:p-12 border-2 m-4 rounded-lg flex flex-col items-center justify-center'>

        <div className='w-[100%] flex flex-col items-start justify-center text-left'>
          <span className='py-2 text-[13px] sm:text-lg font-[500]'>Email</span>
          <input className='p-3 w-full text-[13px] sm:text-lg rounded-lg border-2' value={form.email} onChange={(e) => setForm({...form, 'email': e.target.value})} type="email" required />
        </div>

        <div className='w-[100%] flex flex-col items-start justify-center text-left'>
          <span className='py-2 text-[13px] sm:text-lg font-[500]'>Password</span>
          <input className='p-3 w-full text-[13px] sm:text-lg rounded-lg border-2' value={form.password} onChange={(e) => setForm({...form, 'password': e.target.value})} type="password" required />
        </div>

        <div className='my-6'>
          <CloudflareTrustlineReChapcha setIsVerifed={setIsVerifed}/>
        </div>

        <button className='py-2 sm:py-4 w-[100%] bg-blue-400 px-8 rounded-lg text-[16px] sm:text-xl font-[500] hover:bg-blue-500 mt-3'>Login</button>
      </form>
    </main>
  )
}

export default LogIn
