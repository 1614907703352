import React from 'react'

const Page404 = () => {
  return (
    <section className='w-full min-h-[100vh] flex flex-col items-center justify-center py-16 sm:py-48 font-inter text-left'>
      <div className='w-[90%] lg:w-[67.5%] flex flex-col items-center justify-center font-oswald'>
        <h1 className='text-[40vw] sm:text-[250px]'>404</h1>
        <h2 className='text-[10vw] sm:text-[50px]'>Page not found</h2>
      </div>
    </section>
  )
}

export default Page404
