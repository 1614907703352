import React, { useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser';
import CloudflareTrustlineReChapcha from './CloudflareTrustlineReChapcha';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'

const FormContactUs = () => {
  const textareaRef = useRef(null);

  const env = process.env

  const handleInput = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight + 5}px`;
    }
  };

  const [contactForm, setContactForm] = useState({
    fullName: '',
    address: '',
    emailAddress: '',
    phoneNumber: '',
    question: '',
    agreeTermsAndPrivacyPolicy: false
  }); 


  console.log(contactForm)

  const [isVerifed, setIsVerifed] = useState(null)

  const [isSent, setIsSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const form = useRef(null);

  const sendEmail = async (e) => {
    e.preventDefault();

    if (isVerifed) {
      if (form.current) {
        setIsLoading(true)
        
        await emailjs
          .sendForm(env.REACT_APP_EMAILJS_SERVICE_KEY, env.REACT_APP_EMAILJS_TEMPLATE_KEY, form.current, {
            publicKey: env.REACT_APP_EMAILJS_PUBLIC_KEY,
          })
          .then(
            () => {
              console.log('SUCCESS!');
              setIsLoading(false)
              setIsSent(true)
              setIsError(false)
            },
            (error) => {
              console.log('FAILED...', error.text);
              setIsLoading(false)
              setIsError(true)
            },
          );
      } else {
        setIsLoading(false)
      }
    }
  };
  
  const changeContactForm = (fieldName, value) => {
    setContactForm({...contactForm, [fieldName]: value})
  }

  useEffect(() => {
    if (isSent) {
      const timer = setTimeout(() => {
        setIsSent(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isSent]);


  const socialMediaLinks = [
    {icon: 'facebook', link: 'https://www.facebook.com/'},
    {icon: 'whatsapp', link: 'https://web.whatsapp.com/'},
    {icon: 'twitter', link: 'https://x.com/'},
    {icon: 'instagram', link: 'https://www.instagram.com/'},
  ]

  return (
    <section className='w-full min-h-[70vh] flex flex-col items-center justify-center font-inter text-left pb-10 xl:pb-0'>

      <div className='w-full lg:w-[1000px] xl:w-[90%] h-full flex flex-col items-center justify-center'>
 
        <h1 className='text-[18px] sm:text-[35px] lg:text-[50px] font-[600] w-[90%] md:w-[80%] lg:w-[65%] bg-[#2e3092] text-white px-10 leading-[40px] sm:leading-[55px] sm:my-6'>Contact Us</h1>

        <form ref={form} onSubmit={(e) => sendEmail(e)} className='flex flex-col items-center justify-center space-y-5 h-full w-[90%] md:w-[80%] lg:w-[65%] mt-5 sm:mt-0 p-5 border-2'>

          <span className='text-[12px] sm:text-[18px] text-[#e65757] font-[500] w-full'>*Required information</span>

          <div className='w-full flex flex-col'>
            <span className='font-[400] font-[roboto] mb-1 text-[12px] sm:text-[18px] uppercase flex justify-start'>Full Name  <span className='text-[#e65757] mx-1 font-[600]'>*</span> </span> 
            <input onChange={(e) => changeContactForm('fullName', e.target.value)} placeholder='Enter full name here' required className='w-[100%] mb-1 bg-white text-[10px] sm:text-[16px] focus:outline-none p-3 py-2 rounded-[17px] border-[2px]' type="text" name='fullName' />
          </div>

          <div className='w-full flex flex-col'>
            <span className='font-[400] font-[roboto] mb-1 text-[12px] sm:text-[18px] uppercase flex justify-start'>Address  <span className='text-[#e65757] mx-1 font-[600]'>*</span> </span> 
            <input onChange={(e) => changeContactForm('address', e.target.value)} placeholder='Enter address here' required className='w-[100%] mb-1 bg-white text-[10px] sm:text-[16px] focus:outline-none p-3 py-2 rounded-[17px] border-[2px]' type="text" name='address' />
          </div>

          <div className='w-full flex flex-col sm:flex-row items-center justify-between space-y-5 sm:space-y-0 sm:space-x-4'>
          
            <div className='w-full flex flex-col'>
              <span className='font-[400] font-[roboto] mb-1 text-[12px] sm:text-[18px] uppercase flex justify-start'>Email Address  <span className='text-[#e65757] mx-1 font-[600]'>*</span> </span> 
              <input onChange={(e) => changeContactForm('emailAddress', e.target.value)} placeholder='example@domain.com' required className='w-[100%] mb-1 bg-white text-[10px] sm:text-[16px] focus:outline-none p-3 py-2 rounded-[17px] border-[2px]' type="text" name='emailAddress' />
            </div>

            <div className='w-full flex flex-col'>
              <span className='font-[400] font-[roboto] mb-1 text-[12px] sm:text-[18px] uppercase flex justify-start'>Phone Number  <span className='text-[#e65757] mx-1 font-[600]'>*</span> </span> 
              <div className='mb-1 bg-white text-[10px] sm:text-[16px] focus:outline-none p-3 py-2 rounded-[17px] border-[2px]'>
                <PhoneInput onChange={(value) => changeContactForm('phoneNumber', value)} className='' placeholder='+1 (number)'/>
              </div>
            </div>

          </div>

          <div className='w-full flex flex-col'>
            <span className='font-[400] font-[roboto] mb-1 text-[12px] sm:text-[18px] uppercase flex justify-start'>Question / Comment  <span className='text-[#e65757] mx-1 font-[600]'>*</span> </span> 
            {/* <input onChange={(e) => changeContactForm('question', e.target.value)} placeholder='Enter your question or comment here' required className='w-[100%] mb-1 bg-white text-[10px] sm:text-[16px] focus:outline-none p-3 py-2 rounded-[17px] border-[2px]' type="text" name='question' /> */}
            <textarea
              ref={textareaRef}
              placeholder='Enter your question or comment here'
              rows={1}
              className='w-[100%] mb-1 bg-white text-[10px] resize-none sm:text-[16px] focus:outline-none p-3 py-2 rounded-[17px] border-[2px]'
              onInput={handleInput} 
              onChange={(e) => changeContactForm('message', e.target.value)}
              name='message'
              required
            />
          </div>


          <div className='w-full flex flex-col lg:flex-row items-center justify-between space-y-4 sm:space-y-0 h-full'>
            <div className='w-full lg:w-[60%] flex flex-col justify-start h-[200px]'>
              <div className='w-full flex items-center space-x-2 text-[10px]  sm:text-[16px] font-[600] '>
                <input onChange={(e) => changeContactForm('agreeTermsAndPrivacyPolicy', e.target.checked)} value={contactForm.agreeTermsAndPrivacyPolicy} type="checkbox" required />
                <span>By submitting this form you agree to the <Link className='text-[blue] hover:border-b border-[blue]' to='/terms-and-services'>terms </Link> and <Link className='text-[blue] hover:border-b border-[blue]' to='/privacy-policy'>Privacy Policy.</Link>  </span>
              </div>

              <div className='flex flex-wrap items-start justify-start w-full sm:space-x-4 m-auto'>
                <CloudflareTrustlineReChapcha setIsVerifed={setIsVerifed}/>
                <button 
                  className={`
                    ${
                      isLoading ? '!text-white animate-pulse !bg-blue-500': 
                      isError ? '!bg-red-500 !text-white' :
                      isSent ? '!bg-green-600 !text-white' :  ''
                    }

                     p-4 px-8 sm:p-6 sm:px-14 bg-[#9ed41e] text-white rounded-[15px] text-[14px] sm:text-[18px] font-[500] uppercase transition-all duration-500
                    `}
                    >
                  <span className='mx-1'> 
                    {
                      isLoading ? 'Loading...' 
                      : isError ? 'Error, please try again'
                      : isSent ? 'Message sent successfully' 
                      : 'Submit'
                    } 
                  </span> 
                  
                </button>
              </div>

            </div>



            <div className='w-full lg:w-[40%] flex flex-col items-start lg:items-end justify-end'>
              <div className=''>
                <img className='h-[120px] hidden sm:block' src="contant-us-form.jpg" alt="" />

                <div className='flex flex-wrap items-center justify-start sm:justify-between my-2'>
                  {socialMediaLinks.map((link, i) => 
                    <Link key={i} className='sm:h-[50px] w-[50px] m-1' target='_blank' to={link.link}>
                      <img className='rounded-full' src={link.icon + '-icon.png'} alt={link.icon} />
                    </Link>
                  )}
                </div>

              </div>
            </div>

          </div>
        </form>
      </div>
            
    </section>
  )
}

export default FormContactUs
