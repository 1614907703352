import React, { useEffect } from 'react'
import {useLoaderData, useParams} from 'react-router-dom'
import Page404 from '../Layout/Page404'
import BlackBtn from '../../Components/BlackBtn'
import {useActions} from '../../../hooks/useActions'
import TextComponent from '../../Components/TextComponent'
import { useSelector } from 'react-redux';

const ServicePage = () => {
  const loadedData = useLoaderData()
  const {services} = useSelector(state => state.sections.sections)
  const {setSectionField} = useActions('sections')
  const [currentService, setCurrentService] = React.useState({})

  const {serviceName} = useParams()
  
  useEffect(() => {
    setCurrentService(services?.services?.filter((service) => service?.rmPath === serviceName)[0])
  }, [services?.services])

  const updateHandler = (rmPathT, value, fieldName) => {
    const updatedService = services?.services?.map(item => {
      if (item.rmPath === rmPathT) {
        return { ...item, [fieldName]: value };
      }
      return item
    });
  
    setSectionField(services?.title_key, 'services', updatedService);
  };

  return loadedData.error === 404 ? <Page404/> : (
    <>
    
      <section className='w-full min-h-[70vh] flex flex-col items-center justify-center font-inter text-left'>
        <div className='w-[90%] lg:w-[55%] flex flex-col items-center justify-end font-inter text-center'>
          <TextComponent
            Tag={"h1"}
            className='text-[16px] sm:text-[45px] font-[700] w-full text-center my-5 sm:my-6' 
            textEditorClassName='w-full'
            title_key={services?.title_key}
            fieldName='title'
            value={currentService?.title}
            customHandler={(value) => updateHandler(currentService?.rmPath, value, 'title')}
            />
            <TextComponent
              Tag={"p"}
              className='font-[400] text-[12px] lg:text-[20px]  lg:leading-[26px] text-center w-full' 
              textEditorClassName='w-full'
              title_key={services?.title_key}
              fieldName='desc'
              value={currentService?.desc}
              customHandler={(value) => updateHandler(currentService?.rmPath, value, 'desc')}
            />
          <BlackBtn title='MORE SEVICES' className='sm:py-[20px] sm:px-[40.055px] whitespace-nowrap mt-8 sm:w-[177px] sm:text-[14px] font-[600]' to='/our-services'/>
        </div>

      </section>
    </>
  )
}

export default ServicePage


export const loadServiceData = async (serviceName) => {
  console.log(serviceName)
  const services = [  
    {
      title: 'Data Migration',
      desc: 'We specialize in migrating your data from your old system to a new one. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      serviceName: 'data-migraiton'  
    },
    {

      title: 'Side by Side Data Synchronization: ',
      desc: 'Transitioning from on-premise to the cloud? Our side-by-side data  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      serviceName: 'data-synchronization'  
    },
    {
      title: 'Database Migration',
      desc: 'Migrating data from PostgreSQL or MySQL to Amazon Redshift?',
      serviceName: 'migration'  
    },
    {
      title: 'RDS to Aurora Migration',
      desc: 'Upgrade from Amazon RDS to Aurora for enhanced performance and scalability Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      serviceName: 'rds-to-aurora-migration'  
    },
    {
      title: 'EC2/On-premise to RDS Migration',
      desc: 'Moving from EC2 instances or on-premise servers to Amazon RDS?  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      serviceName: 'ec2-on-premise-to-rds-migraiton'  
    },
    {
      title: 'Aurora Setup from Scratch',
      desc: 'Leverage the power of Amazon Aurora with our setup- from-scratch  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      serviceName: 'aurora-setup-from-scratch'  
    },
    {
      title: 'Table Partitioning for PostgreSQL',
      desc: 'Enhance the performance of your PostgreSQL database with our table  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      serviceName: 'table-partitioning-for-postgresql'  
    },
  ] 

  const foundedService = services.find(service => service.serviceName === serviceName)

  return foundedService ? foundedService : {'error': 404}  
}
