import React from 'react'
import './Home.scss'
import Welcome from './Sections/Welcome'
import AboutUsSection from './Sections/AboutUsSection'
import OurServices from './Sections/OurServices'
import OurFeatures from './Sections/OurFeatures'
import FAQ from './Sections/FAQ'
import GetStarted from '../../Components/GetStarted'
import Advantages from './Sections/Advantages'

const Home = () => {
  
  return (
    <>
      <Welcome/>
      <Advantages/>
      {/* <AboutUsSection/>
      <OurServices/>
      <OurFeatures/>
      <FAQ/> */}
      <GetStarted/>
    </>
  )
}

export default Home
