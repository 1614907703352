import React from 'react'
import Modal from './Modal'
import Icons from '../Components/Icons'

const ModalConfirmSaveSections = ({isActive, setIsActive, acceptCallback, rejectCallback}) => {
  return (
    <Modal isActive={isActive}>
      <div className='bg-white rounded-md p-5 sm:px-10 pb-5 flex flex-col w-[95%] sm:w-[500px] items-center justify-between relative'>

        <button className='absolute top-3 right-3' onClick={() => setIsActive?.(false)}>
          <Icons icon={'cross'} className='h-6 w-6'/>
        </button>

        <h1 className='font-[600] text-[16px] sm:text-[18px] lg:text-[22px]'>
          Are you sure you want to update all the content on the website?
        </h1>

        <div className='w-full flex items-end justify-center space-x-3 mt-5 sm:mt-16'>
          <button className='w-1/2 sm:w-1/3 text-white rounded-lg p-2 bg-[#f34e4e] hover:bg-[#d74545] sm:py-[8px] sm:px-[10px] whitespace-nowrap text-[20px] sm:text-[25px] font-[600]' onClick={rejectCallback}>No</button>
          <button className='w-1/2 sm:w-1/3 text-white rounded-lg p-2 bg-[#41b941] hover:bg-[#3ead3e] sm:py-[8px] sm:px-[10px] whitespace-nowrap text-[20px] sm:text-[25px] font-[600]' onClick={acceptCallback}>Yes</button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalConfirmSaveSections
