import React from 'react'
import TextComponent from '../../Components/TextComponent'
import { useActions } from '../../../hooks/useActions';
import { useSelector } from 'react-redux';

const Paragraph = ({i, title, desc, updateHandler}) => {
  const {termsAndServices} = useSelector(state => state.sections.sections)

  return (
    <div className='text-left w-full'>
      <TextComponent
        Tag={"span"}
        className='text-[18px] xl:text-[30px] font-[700] text-left w-full' 
        textEditorClassName='w-full'
        title_key={termsAndServices?.title_key}
        fieldName='title'
        value={title}
        customHandler={(value) => updateHandler(i, value, 'title')}
      />
      <TextComponent
        Tag={"p"}
        className='text-[10px] lg:text-[19px] font-[550] whitespace-pre-wrap' 
        textEditorClassName='w-full'
        title_key={termsAndServices?.title_key}
        fieldName='desc'
        value={desc}
        customHandler={(value) => updateHandler(i, value, 'desc')}
      />
    </div>
  )
}

export default Paragraph
