import React from 'react'
import '../../../../firebase/firebase'
import {useSelector} from 'react-redux'
import TextComponent from '../../../Components/TextComponent'
import Icons from '../../../Components/Icons'
import { useActions } from '../../../../hooks/useActions'

const Welcome = () => {
  const {welcome} = useSelector(state => state.sections.sections)

  const {setSectionField} = useActions('sections')

  const updateServicesList = (index, value) => {
    const updatedServicesList = welcome?.services?.map((item, i) => {
      if (i === index) {
        return { ...item, title: value };
      }
      return item
    });
  
    setSectionField(welcome?.title_key, 'services', updatedServicesList);
  };

  return (
    <section className='w-full min-h-[50vh] flex items-center justify-center border-dotted-section mt-5'>
      <div className='w-[98%] sm:w-[90%] xl:w-[74%] h-[80%] flex flex-col sm:flex-row items-center justify-between p-5 sm:p-0'>
        <div className='flex flex-col items-center justify-end space-y-5 w-full sm:w-[44%] font-inter sm:mr-5 text-left'>
          
          <TextComponent 
            Tag={"h1"} 
            className='w-full text-[30px] md:text-[40px] xl:text-[60px] font-[700] leading-[35px] sm:leading-[35px] lg:leading-[40px] xl:leading-[70px] text-left'
            title_key={welcome?.title_key}
            fieldName='title'
            value={welcome?.title}
          />

          <TextComponent 
            Tag={"p"} 
            className='text-[10px] lg:text-[19px] font-[550] text-left'
            title_key={welcome?.title_key}
            fieldName='desc'
            value={welcome?.desc}
          />
        
          <div className='w-full flex flex-col justify-start'>
            {welcome?.services?.map((service, i) => 
              <div className='flex items-center justify-start space-x-2 m-2' key={i}>
                <Icons icon={service.icon} className='h-[30px]'/>
                <TextComponent
                  Tag={"p"}
                  className='text-[14px] lg:text-[18px] font-[500] text-[#2e3092]'
                  title_key={welcome?.title_key}
                  fieldName='supportingDbsList'
                  value={service.title}
                  customHandler={(value) => updateServicesList(i, value)}
                />
              </div> 
            )}
          </div>

        </div>
        <div className='w-[80%] sm:w-[50%] flex justify-end'>
          <img src="welcome.png" alt="Welcome" />
        </div>
      </div>
    </section>
  ) 
}

export default Welcome


