import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

const NavbarLink = ({path, name}) => {
  const location = useLocation();
  const [isCurrentPath, setIsCurrentPath] = useState(false);

  useEffect(() => {
    setIsCurrentPath(location.pathname === path);
  }, [location.pathname, path]);

  return (
    <span className='inline-block relative'>
      <Link to={path} className="effect-box h-full after:mt-[2px]" >
        <span className={`sm:my-6 mx-1 sm:mx-3 text-[10px] sm:text-[14px] font-[800] whitespace-nowrap ${isCurrentPath ? 'text-gradient-yellow' : ''}`}>{name}</span>
      </Link>
    </span>
  )
}

export default NavbarLink
