import React, { useEffect, useRef } from 'react'

const InputContentChange = ({className, value, handler}) => {
  const textareaRef = useRef()

  const handleInput = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => handleInput(), [])

  return (
    <textarea
      ref={textareaRef}
      rows={1}
      defaultValue={value}
      className={`border ${className} overflow-hidden resize-none w-full bg-transparent`}
      onChange={(e) => handler?.(e.target.value)}
      onInput={handleInput}   
      required
    />
  )
}

export default InputContentChange
