import React from 'react'
import GetStarted from '../../Components/GetStarted'
import OurMission from './OurMission'
import { useSelector } from 'react-redux'
import TextComponent from '../../Components/TextComponent'

const AboutUs = () => {
  const {aboutUs} = useSelector(state => state.sections.sections);

  return (
    <>
      <section className='w-full min-h-[55vh] flex items-center justify-center py-4 sm:py-0'>
        <div className='w-[90%] xl:w-[70%] h-[80%] flex flex-col sm:flex-row items-center justify-between space-y-10 sm:space-x-10'>
          <div className='flex flex-col items-start justify-end w-[95%] sm:w-[44%] font-inter sm:mr-5 text-left'>
            
            <TextComponent
              Tag={"h1"}
              className='text-[30px] xl:text-[50px] font-[700]'
              title_key={aboutUs?.title_key}
              fieldName='title'
              value={aboutUs?.title}
            />

            <TextComponent
              Tag={"p"}
              className='text-[10px] lg:text-[19px] font-[500] my-4 text-left'
              title_key={aboutUs?.title_key}
              fieldName='subdesc'
              value={aboutUs?.subdesc}
            />
            
            <TextComponent
              Tag={"h2"}
              className='text-[18px] xl:text-[40px] font-[700] text-left'
              title_key={aboutUs?.title_key}
              fieldName='subtitle'
              value={aboutUs?.subtitle}
            />
            
            <TextComponent
              Tag={"p"}
              className='text-[10px] lg:text-[19px] font-[500] text-left'
              title_key={aboutUs?.title_key}
              fieldName='desc'
              value={aboutUs?.desc}
            />
            
          </div>
          <div className='w-[80%] sm:w-[47%] flex justify-end h-[68%]'>
            <img className='h-full w-full' src="AboutUs.png" alt="Welcome" draggable='false' />
          </div>
        </div>
      </section>

      <OurMission/>
      
      <GetStarted/>
    </>
  )
}

export default AboutUs;
