import React from 'react'
import { Link } from 'react-router-dom'

const SocialMediaLink = ({icon, title, link, inNewScreen, isWithAnimation}) => {
  return (
    <span className='inline-block relative'>
      <Link className={isWithAnimation && 'effect-box h-full after:mt-[2px]'} to={link} target={inNewScreen && '_blank'}>
        {icon && <div><img src={`/Social-Media-Icons/${icon}.png`} alt={icon}/></div>}
        {title && <span className='font-[300] sm:my-8 mx-1 sm:mx-3 text-[12px] md:text-[18px] whitespace-nowrap'>{title}</span>}
      </Link>
    </span>
  )
}

export default SocialMediaLink
