import { bindActionCreators } from '@reduxjs/toolkit'
import { useMemo } from 'react'
import {useDispatch} from 'react-redux' 
import * as sections from '../store/actions/sectionsActions'
import * as auth from '../store/actions/authActions'

const rootActions = { sections, auth }

export const useActions = (reducerName) => {
  const dispatch = useDispatch()

  return useMemo(() => bindActionCreators(rootActions[reducerName] || {}, dispatch), [dispatch])
}
