import firebase from 'firebase/compat/app';
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

const env = process.env

console.log(env)

// Ваша конфігурація Firebase
export const firebaseConfig = {
  apiKey: env.REACT_APP_FIREBASE_APIKEY,
  authDomain: env.REACT_APP_AUTHDOMAIN,
  projectId: env.REACT_APP_PROJECTID,
  storageBucket: env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: env.REACT_APP_MESSAGINGSENDERID,
  appId: env.REACT_APP_APPID,
};



firebase.initializeApp(firebaseConfig);


// Експорт Firebase
export default firebase;

export const firestore = firebase.firestore()
// Додаткові експорти (за потреби)
export const auth = firebase.auth();
// export const db = firebase.database();
// export const firestore = firebase.firestore();
// export const storage = firebase.storage();