import { firestore, auth } from "./firebase"

export const updateSectionsDataFirestore = async (updatedSecitons) => {

  const token = auth.currentUser.getIdToken()

  if (token) {
    const sectionsSnapshot = await firestore.collection("sections").get()
  
    sectionsSnapshot.forEach(sectionDoc => {
      try {
        const secitonRef = sectionDoc.ref
        const updatedSeciton = updatedSecitons[sectionDoc.data().title_key]
        updatedSeciton && secitonRef.update(updatedSeciton); console.log('UPDATED!!!')
      } catch (e) {
        console.error(e)
      }
    })

    console.log("SUCCESS!!!")
  } else {
    auth.signOut()
  }
}