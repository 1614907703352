import { createHashRouter, Navigate } from "react-router-dom";
import Home from "../Pages/Home/Home";
import AboutUs from "../Pages/AboutUs/AboutUs";
import Layout from "../Pages/Layout/Layout";
import ContactUs from "../Pages/ContactUs/ContactUs";
import { ScrollToTopOnPageChange } from "../Pages/Layout/ScrollToTopOnPageChange";
import PrivacyPolicy from "../Pages/Documents/PrivacyPolicy";
import TermsAndServices from "../Pages/Documents/TermsAndServices";
import OurServices from "../Pages/Home/Sections/OurServices";
import OurFeatures from "../Pages/Home/Sections/OurFeatures";
import FAQ from "../Pages/Home/Sections/FAQ";
import GetStarted from "../Components/GetStarted";
import ServicePage, { loadServiceData } from "../Pages/Services/ServicePage";
import LogIn from "../Pages/Auth/LogIn";
import { loadSections } from "../../loaders/loaders";
import OurServicesPage from "../Pages/Services/OurServicesPage";

export const SERVER_URL = 'http://localhost:3001'

const router = createHashRouter([
  {
    path: '/',
    element: <Navigate to={'/home'}/>
  },
  {
    path: '/',
    element: <>
      <ScrollToTopOnPageChange/>
      <Layout/>
    </>,
    loader: loadSections,
    children: [
      {
        path: '/home',
        element: <Home/>,
      },
      {
        path: '/about-us',
        element: <AboutUs/>
      }, 
      {
        path: '/contact-us',
        element: <ContactUs/>
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicy/>
      },
      {
        path: '/terms-and-services',
        element: <TermsAndServices/>
      },
      {
        path: '/our-services',
        children: [
          {
            index: true,
            element: <><OurServicesPage/> <GetStarted/></>, 
          },
          {
            path: "*",
            element: <>404</>
          },
          {
            path: ':serviceName',
            element: <ServicePage/>,
            loader: ({params}) => loadServiceData(params.serviceName)
          }
        ]
      },  
      {
        path: '/our-features',
        element: <><OurFeatures/> <GetStarted/></> 
      },
      {
        path: '/faqs',
        element: <><FAQ/> <GetStarted/></> 
      }
    ]
  },     
  {
    path: '/login',
    element: <LogIn/>
  } 
])

export default router