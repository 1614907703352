import { useEffect } from "react";
import { useLocation } from "react-router";

// Імпортуємо необхідні компоненти та функції

export const ScrollToTopOnPageChange = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null
};
