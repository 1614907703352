import React from 'react'
import ServiceCard from '../../../Components/ServiceCard'
import {useSelector} from 'react-redux'

const OurServices = () => {
  const {services} = useSelector(state => state.sections.sections)

  // const services = [
  //   {
  //     icon: 'db-migration',
  //     title: 'Data Migration',
  //     desc: 'We specialize in migrating your data from your old system to a new one.',
  //     rmPath: 'data-migraiton'  
  //   },
  //   {
  //     icon: 'cloud',
  //     title: 'Side by Side Data Synchronization: ',
  //     desc: 'Transitioning from on-premise to the cloud? Our side-by-side data ',
  //     rmPath: 'data-synchronization'  
  //   },
  //   {
  //     icon: 'migration',
  //     title: 'Database Migration',
  //     desc: 'Migrating data from PostgreSQL or MySQL to Amazon Redshift?',
  //     rmPath: 'migration'  
  //   },
  //   {
  //     icon: 'db-star',
  //     title: 'RDS to Aurora Migration',
  //     desc: 'Upgrade from Amazon RDS to Aurora for enhanced performance and scalability',
  //     rmPath: 'rds-to-aurora-migration'  
  //   },
  //   {
  //     icon: 'cpu',
  //     title: 'EC2/On-premise to RDS Migration',
  //     desc: 'Moving from EC2 instances or on-premise servers to Amazon RDS? ',
  //     rmPath: 'ec2-on-premise-to-rds-migraiton'  
  //   },
  //   {
  //     icon: 'db-star',
  //     title: 'Aurora Setup from Scratch',
  //     desc: 'Leverage the power of Amazon Aurora with our setup- from-scratch ',
  //     rmPath: 'aurora-setup-from-scratch'  
  //   },
  //   {
  //     icon: 'db-star',
  //     title: 'Aurora Setup from Scratch',
  //     desc: 'Leverage the power of Amazon Aurora with our setup- from-scratch ',
  //     rmPath: 'aurora-setup-from-scratch'  
  //   },
  //   {
  //     icon: 'postgresql',
  //     title: 'Table Partitioning for PostgreSQL',
  //     desc: 'Enhance the performance of your PostgreSQL database with our table ',
  //     rmPath: 'table-partitioning-for-postgresql'  
  //   },
  // ]

  return (
    <section className='w-full flex items-center justify-center border-dotted-section py-10 sm:pb-0'>
      <div className='w-[90%] xl:w-[60%] flex flex-wrap items-center justify-center sm:justify-center font-inter'>
        {services?.services?.map((service, i) => <ServiceCard key={i} i={i} {...service}/> )}
      </div>
    </section>
  )
}

export default OurServices
